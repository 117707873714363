<template>
    <nav class="main-nav navbar bd-navbar is-fixed-top has-text-centered-mobile" role='navigator' aria-label='main navigation'>
        <a href="https://addyinvest.ca">
            <img class="navlogo" width="90" height="35"
                 :src="PrussianBluePath"
                 alt="Logo orange"/>
        </a>
    </nav>
</template>

<script>
  import {PrussianBluePath} from '../utils/asset-image-paths';

  export default {
    data() {
      return {PrussianBluePath};
    }
  }
</script>
